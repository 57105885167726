import React from "react";
import { Link } from "gatsby";
import Page from "./../components/Page";
import Section from "./../components/Section";
import SEO from "./../components/SEO";

function ReleaseNotes() {
  return (
    <Page>
      <SEO title="Release Notes | OHIF" />
      <Section className="max-w-6xl mx-auto">
        <h1 className="text-5xl text-blue-100 mx-auto text-center">
          Release Notes
        </h1>

        <p className="mt-8">
          At OHIF, we strive for consistent and dependable releases to keep your
          medical imaging workflows cutting-edge and reliable. Within this page,
          you'll discover a comprehensive list of our latest and past releases,
          complete with detailed release notes.
        </p>

        <ul className="mt-8 text-primary-light">
          <Link to="/release-notes/3p8">
            <li>Latest (v3.8.0)</li>
          </Link>
          <Link to="/release-notes/3p7">
            <li>v3.7.0</li>
          </Link>
          <Link to="/release-notes/previous">
            <li>Previous releases</li>
          </Link>
        </ul>
      </Section>
    </Page>
  );
}

export default ReleaseNotes;
